<template>
  <div class="add_box">
    <ContractInfo ref="contractInfo" @oneCon="oneCon"></ContractInfo>
    <PaymentPlan ref="paymentPlan" @addPlan="addPlan"></PaymentPlan>
    <!-- <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onClose" round>保 存</el-button>
        </div> -->
  </div>
</template>

<script>
import ContractInfo from "./components/contactInfo.vue";
import PaymentPlan from "./components/paymentPlan.vue";
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {
    ContractInfo,
    PaymentPlan,
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$refs.contractInfo.getData(this.$route.query.disabled);
      this.$refs.paymentPlan.getData(this.$route.query.disabled);
    },
    onClose() {
      this.$emit("close");
    },

    addPlan() {
      if (this.$refs.contractInfo.$data.form.contractId) {
        this.$refs.paymentPlan.$data.dialog = true;
        this.$refs.paymentPlan.$data.form.contractA = JSON.parse(
          JSON.stringify(this.$refs.contractInfo.$data.oneCon.contractA)
        );
      } else {
        this.$message.error("请选择关联合同");
      }
    },
    oneCon(data) {
      this.$refs.paymentPlan.$data.oneCon = data;
      this.$refs.paymentPlan.planList(data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.add_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f0f0f0;
  .footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
      background-color: #2370eb;
      border-color: #2370eb;
    }
    button:last-child {
      margin-right: 30px;
    }
  }
}
</style>
