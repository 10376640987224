<template>
    <div
        class="contact_info_box"
        :style="{
            background: 'url(' + require('@/assets/bg2.png') + ') no-repeat',
            backgroundSize: 'cover',
        }"
    >
        <p class="title">
            <i class="iconfont icon-huikuan"></i>
            新增回款计划
            <el-button @click="goBack">
                <i class="el-icon-arrow-left"></i> 返回列表
            </el-button>
        </p>
        <el-form
            label-position="left"
            label-width="80px"
            size="small"
            v-if="disabled == 'true'"
        >
            <el-form-item label="关联合同" required>
                <el-select
                    v-model="form.contractId"
                    placeholder="输入客户名称或合同编号搜索"
                    :filter-method="conListFilter"
                    filterable
                    style="width:270px"
                    @change="getOneCon"
                >
                    <el-option
                        v-for="item in filterData"
                        :key="item.id"
                        :label="item.contractNo"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <p class="info">
            <span>合同编号</span>
            {{ oneCon.contractNo ? oneCon.contractNo : '- -' }}
        </p>
        <p class="info">
            <span>客户名称</span>
            {{ oneCon.companyName ? oneCon.companyName : '- -' }}
        </p>
        <p class="info">
            <span>合同金额</span>
            {{ oneCon.contractAmount ? oneCon.contractAmount : '- -' }}
        </p>
    </div>
</template>

<script>
import { contractList } from '@/api/contr/contr.js';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
            disabled: '',
            conList: [],
            filterData: [],
            oneCon: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(disabled) {
            // console.log(disabled);
            this.disabled = disabled;
            if (this.$route.query.id) {
                let data = {
                    param: {
                        id: this.$route.query.id,
                    },
                };
                contracGet(data).then((res) => {
                    // console.log(res);
                    this.oneCon = res.data;
                    this.form.contractId = res.data.id;
                    this.$emit('oneCon', res.data);
                });
            } else {
                this.contractList();
            }
        },
        contractList() {
            let data = {
                param: {
                    statusList: [7, 8, 10, 11, 12],
                    returnStatusList: [1, 2, 3],
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            contractList(data).then((res) => {
                this.conList = res.data.list;
                this.filterData = res.data.list;
            });
        },
        conListFilter(val) {
            // console.log(val);
            if (val) {
                this.filterData = this.conList.filter((item) => {
                    if (
                        item.contractNo.indexOf(val) != -1 ||
                        item.companyName.indexOf(val) != -1
                    ) {
                        return true;
                    }
                });
            } else {
                this.filterData = this.conList;
            }
        },
        getOneCon(val) {
            let obj = this.conList.find((item) => item.id == val);
            this.oneCon = obj;
            this.$emit('oneCon', obj);
        },
        goBack() {
            this.$router.go(-1);
            this.oneCon = {};
            this.conList = [];
            this.filterData = [];
            this.form = {};
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding-bottom: 16px;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 25px;
        float: left;
        box-sizing: border-box;
        width: 30%;
        span {
            color: #666666;
            display: inline-block;
            width: 80px;
        }
    }
}
</style>
