<template>
    <div class="payment_plan_box">
        <p class="title">
            回款计划
            <span><em>合计</em>{{ allMoney.toFixed(2) }}<i>元</i></span>
            <el-button @click="add" v-if="allMoney < oneCon.contractAmount"
                >+ 添加计划</el-button
            >
        </p>
        <div class="plan_list">
            <div
                class="list_item"
                v-for="(item, index) in planFormList"
                :key="index"
            >
                <p class="item_title">
                    计划{{ index + 1 }}
                    <em :class="item.returnStatus == 1 ? 'off' : 'on'">
                        <i
                            :class="
                                item.returnStatus == 1
                                    ? 'el-icon-error'
                                    : 'el-icon-success'
                            "
                        ></i>
                        {{ item.returnStatus == 1 ? '未回款' : '已回款' }}
                    </em>
                    <span
                        v-if="
                            index > 0 &&
                            btnP.del &&
                            item.returnStatus == 1 &&
                            item.billingStatus == 1
                        "
                        @click="del(item)"
                        style="color: red !important"
                    >
                        删除
                    </span>
                    <span
                        class="edit"
                        @click="edit(item)"
                        v-if="btnP.upData && item.returnStatus == 1"
                    >
                        <i class="iconfont el-icon-edit-outline"></i>编辑
                    </span>
                </p>
                <div class="on_box">
                    <el-form
                        label-position="left"
                        label-width="80px"
                        size="small"
                    >
                        <el-form-item label="回款金额" style="width: 50%">
                            <div class="info">
                                {{ item.returnAmount || '- -' }}
                            </div>
                        </el-form-item>
                        <el-form-item label="回款比例">
                            <div class="info">
                                {{
                                    (
                                        (item.returnAmount /
                                            item.contractAmount) *
                                        100
                                    ).toFixed(2) + '%'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item label="回款年月" style="width: 50%">
                            <div class="info">
                                {{
                                    item.returnTime
                                        ? item.returnTime.slice(0, 7)
                                        : '- -' || '- -'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item label="付款账户" style="width: 50%">
                            <div class="info">
                                {{ item.contractA ? item.contractA : '- -' }}
                            </div>
                        </el-form-item>
                        <el-form-item label="到款时间" style="width: 50%">
                            <div class="info">
                                {{ item.receivedTime || '- -' }}
                            </div>
                        </el-form-item>
                        <el-form-item label="备注" style="width: 80%">
                            <div class="info">
                                {{ item.remark || '- -' }}
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="img_box" v-if="item.returnStatus == 1">
                        <img
                            :src="item.qrcodeUrl"
                            style="width: 150px; height: 150px"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- 新增计划 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ isEdit ? '编辑' : '新增' }}计划
                </div>
            </template>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="回款金额" required>
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.returnAmount"
                        @input="
                            () => {
                                if (
                                    Number(form.returnAmount) -
                                        onePLan.returnAmount >
                                    oneCon.contractAmount - allMoney
                                ) {
                                    form.returnAmount = '0';
                                    this.$message.error(
                                        '回款金额必须小于合同金额'
                                    );
                                }
                                form.returnAmount = form.returnAmount.replace(
                                    /[^0-9.]/g,
                                    ''
                                );
                            }
                        "
                    ></el-input>
                    <div>
                        剩余回款计划金额：{{
                            (
                                oneCon.contractAmount -
                                allMoney +
                                (Number(onePLan.returnAmount) || 0)
                            ).toFixed(2)
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款比例">
                    <div style="width: 240px">
                        {{
                            form.returnAmount
                                ? (
                                      (form.returnAmount /
                                          oneCon.contractAmount) *
                                      100
                                  ).toFixed(2) + '%'
                                : '- -'
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款年月" required>
                    <el-date-picker
                        style="width: 240px"
                        v-model="form.returnTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="month"
                        placeholder="选择年月"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款账户">
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.contractA"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        rows="3"
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmit"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 删除计划 -->
        <el-dialog
            :visible.sync="dialogDel"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除计划
                </div>
            </template>
            <div style="margin: 30px 0 100px 30px; font-size: 20px">
                是否删除回款计划？
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmitDel"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addPlan, planList, updatePlan, delPlan } from '@/api/contr/money.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                contractA: '',
            },
            planFormList: [],
            disabled: '',
            dialog: false,
            dialogDel: false,
            oneCon: {},
            isEdit: false,
            onePLan: {},
            btnP: {},
            allMoney: 0,
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now();
                },
            },
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row, disabled) {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            // console.log(btnArr);
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            this.disabled = disabled;
        },
        add() {
            this.$emit('addPlan');
            this.isEdit = false;
            this.onePLan = {
                returnAmount: 0,
            };
        },
        del(data) {
            this.dialogDel = true;
            this.onePLan = data;
        },
        handleClose() {
            this.dialog = false;
            this.dialogDel = false;

            this.form = {
                contractA: '',
            };
            this.planList(this.oneCon.id);
        },
        edit(data) {
            this.dialog = true;
            this.isEdit = true;
            this.onePLan = data;
            this.form = JSON.parse(JSON.stringify(data));
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.onePLan.id,
                },
            };
            delPlan(data).then((res) => {
                if (res.code == 200) {
                    this.$message.error('操作成功');
                    this.handleClose();
                }
            });
        },
        planList(id) {
            this.allMoney = 0;
            let data = {
                param: {
                    contractId: id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            planList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.planFormList = res.data.list;
                    res.data.list.forEach((item) => {
                        this.allMoney += item.returnAmount;
                    });
                }
            });
        },
        onSubmit() {
            if (!this.form.returnAmount) {
                return this.$message.error('请输入回款金额');
            }
            if (Number(this.form.returnAmount) === 0) {
                return this.$message.error('请输入回款金额');
            }
            if (!this.form.returnTime) {
                return this.$message.error('请选择回款年月');
            }
            if (this.isEdit) {
                if (
                    this.allMoney +
                        Number(this.form.returnAmount) -
                        this.onePLan.returnAmount >
                    this.planFormList[0].contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        id: this.onePLan.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.oneCon.contractA,
                        contractB: this.oneCon.contractB,
                        returnAmount: Number(this.form.returnAmount).toFixed(2),
                        returnTime: this.form.returnTime,
                        remark: this.form.remark,
                    },
                };
                updatePlan(data).then((res) => {
                    if (res.code == 200) {
                        for (const key in this.form) {
                            this.form[key] = '';
                        }
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            } else {
                // console.log(this.planFormList);
                if (
                    this.allMoney + Number(this.form.returnAmount) >
                    this.oneCon.contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        contractId: this.oneCon.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.oneCon.contractA,
                        contractB: this.oneCon.contractB,
                        returnAmount: Number(this.form.returnAmount).toFixed(2),
                        returnTime: this.form.returnTime,
                        remark: this.form.remark,
                    },
                };
                addPlan(data).then((res) => {
                    if (res.code == 200) {
                        for (const key in this.form) {
                            this.form[key] = '';
                        }
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.payment_plan_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
        height: 48px;
        padding: 0 22px 0 25px;
        position: relative;
        line-height: 48px;
        font-weight: 600;
        color: #333;
        font-size: 14px;
        border-bottom: 1px dashed #e5e5e5;
        span {
            display: inline-block;
            height: 33px;
            background: #f0f4ff;
            border-radius: 7px;
            line-height: 33px;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 15px;
            color: #2370eb;
            margin-left: 5px;
            em {
                font-weight: normal;
                font-size: 14px;
                color: #333;
                font-style: normal;
                width: 90px;
                display: inline-block;
            }
            i {
                font-size: 12px;
                color: #333;
                font-style: normal;
                margin-left: 3px;
            }
        }
        button {
            float: right;
            width: 88px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            text-align: center;
            color: #2370eb;
            padding: 0;
            margin-top: 12px;
        }
    }
    .title:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 16px;
        background: #2370eb;
        border-radius: 1px 1px 0px 0px;
    }
    .plan_list {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 15px 0;
        justify-content: flex-start;
        align-content: start;
        .list_item {
            width: 48%;
            margin-left: 20px;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;

            .item_title {
                font-size: 14px;
                font-weight: 600;
                height: 45px;
                box-sizing: border-box;
                line-height: 45px;
                padding: 0 12px;
                border-bottom: 1px dashed #e5e5e5;
                cursor: pointer;
                span {
                    float: right;
                    font-size: 13px !important;
                    font-weight: normal !important;
                    color: #666 !important;
                    i {
                        margin-right: 5px;
                        font-weight: normal !important;
                        color: #da5120;
                    }
                }
                em {
                    font-style: normal;
                    width: 88px;
                    height: 25px;
                    display: inline-block;
                    font-size: 13px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 13px;
                    margin-left: 15px;
                    font-weight: normal;
                    i {
                        margin-right: 5px;
                    }
                }
                .on {
                    background-color: #e0eaff;
                    i {
                        color: #82a7fc;
                    }
                }
                .off {
                    background: #ece2fe;
                    i {
                        color: #7f3af5;
                    }
                }
            }
            .el-form {
                /deep/ .el-form-item__label {
                    font-weight: 600 !important;
                }
            }
        }
        // .list_item + .list_item {
        //   border-top: 1px dashed #e5e5e5;
        // }
        .list_item:nth-child(2n + 1) {
            margin-bottom: 20px;
        }
        .list_item:nth-child(2n + 2) {
            margin-bottom: 20px;
        }
        .total_box {
            position: absolute;
            bottom: 55px;
            left: 0;
            width: 472px;
            margin: 0 16px 0 12px;
            height: 48px;
            background: #f0f4ff;
            border-radius: 7px;
            font-weight: 600;
            font-size: 14px;
            line-height: 48px;
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 30px;
            span {
                float: right;
                font-size: 20px;
                i {
                    font-style: normal;
                    font-size: 14px;
                }
            }
        }
    }
}
.on_box {
    position: relative;
    padding: 5px 0 0 43px;
    height: calc(100% - 45px);
    .el-form {
        /deep/ .el-form-item__label {
            font-weight: 400 !important;
            color: #666666;
        }
        .info {
            color: #333;
            font-size: 14px;
            word-break: break-all;
            max-width: 290px;
        }
        .el-form-item--small.el-form-item {
            margin-bottom: 5px;
        }
    }
    .img_box {
        display: flex;
        position: absolute;
        left: 385px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.edit {
    i {
        color: #2370eb !important;
        vertical-align: -1px;
    }
    margin-right: 5px;
}
.dilog_box {
    .el-form {
        width: 80%;
        margin: 20px auto 80px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
